<template>
  <div>
    <sdPageHeader title="Mijn profiel"></sdPageHeader>
    <Main>
      <a-row :gutter="25">
        <a-col :xxl="6" :lg="8" :md="10" :xs="24">
          <ProfileAuthorBox>
            <author-box />
          </ProfileAuthorBox>
        </a-col>
        <a-col :xxl="18" :lg="16" :md="14" :xs="24">
          <SettingWrapper>
            <Suspense>
              <template #default>
                <router-view></router-view>
              </template>
              <template #fallback>
                <div class="spin">
                  <a-spin />
                </div>
              </template>
            </Suspense>
          </SettingWrapper>
        </a-col>
      </a-row>
    </Main>
  </div>
</template>

<script>
import { SettingWrapper } from './overview/style';
import AuthorBox from './overview/ProfileAuthorBox';
import { ProfileAuthorBox } from './overview/style';
import {defineComponent, onMounted} from 'vue';
import {Main} from "../styled";
import {useStore} from "vuex";

export default defineComponent({
  name: 'Settings',
  components: {
    Main,
    SettingWrapper,
    AuthorBox,
    ProfileAuthorBox,
  },
  data() {
    return {};
  },
  setup(){
    const {dispatch} = useStore();
    onMounted(() =>{
      dispatch('getProfile');
    });
  }
});
</script>
