<template>
  <sdCards headless>
    <div class="author-info">
      <figure>
        <img
            v-if="profile.imageFileURL && profile.imageFileURL !== ''"
            :src="profile.imageFileURL"
            alt=""
            style="max-width: 120px"
        />
        <img v-else :src="require('../../../static/img/users/1.png')" alt="" />
          <a-upload
              name="file"
              :multiple="false"
              :action="`${fileUrl}auth/me/file`"
              :headers="fileUploadHeaders"
              @change="handleUpload">
            <a class="btn-upload" to="#">
              <sdFeatherIcons type="camera" size="16"/>
            </a>
          </a-upload>
      </figure>
      <figcaption>
        <div class="info">
          <sdHeading as="h4">{{ profile.fullName }}</sdHeading>
          <p>{{ profile.organizationName }}</p>
        </div>
      </figcaption>
    </div>
    <nav class="settings-menmulist">
      <ul>
        <li>
          <router-link :to="{ name: 'profile' }">
            <sdFeatherIcons type="user" size="14" />
            Profiel
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'mePassword' }">
            <sdFeatherIcons type="key" size="14" />
            Wachtwoord wijzigen
          </router-link>
        </li>
      </ul>
    </nav>
  </sdCards>
</template>
<script>
import { computed } from 'vue';
import {useStore} from "vuex";
import {getApiURL} from "../../../config/dataService/dataService";
import {getItem} from "../../../utility/localStorageControl";
export default {
  name: 'AuthorBox',
  components: {},
  data() {
    return {
      fileUrl: getApiURL(),
    };
  },
  setup() {
    const {state, dispatch} = useStore();
    const profile = computed(() => state.profile.profile);

    const handleUpload = async (info) => {
      if (info.file.status === 'done') {
        await dispatch('getProfile');
        await dispatch('fileUploadedSuccess');
      } else if (info.file.status === 'error') {
        await dispatch('fileUploadedError');
      }
    };

    const fileUploadHeaders = {
      Authorization: `Bearer ${getItem('access_token')}`
    };

    return {
      profile,
      handleUpload,
      fileUploadHeaders,
    };
  },
};
</script>
